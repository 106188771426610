import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"

import Appear from "../appear"
import AppearTypes from "../appear/types"

const Container = (props) => {

  const View = (appearClasses) => (
    <div className={clsx("container", props.className,
      props.direction && `direction-${props.direction}`,
      props.padding && `padding-${props.padding}`,
      props.justify && `justify-${props.justify}`,
      props.alignItems && `align-items-${props.alignItems}`,
      props.justifyContent && `justify-content-${props.justifyContent}`,
      props.sizeItems && `size-items-${props.sizeItems}`,
      props.spaceItems && `space-items-${props.spaceItems}`,
      props.background && `background-color-${props.background}`,
      appearClasses,
    )}>
      { props.children }
    </div>
  )

  return props.appear
    ? <Appear asUtil { ...AppearTypes[props.appear] } onAppear={props.onAppear}>
        { View }
      </Appear>
    : View()
}


export const ContainerPropTypes = {
  className: (
      PropTypes.string
  ),
  direction: PropTypes.oneOf([
    "row", "column", "row-reverse", "column-reverse",
  ]),
  padding: PropTypes.oneOf([
    "horizontal", "vertical", "full",
  ]),
  justify: PropTypes.oneOf([
    "left", "right", "center",
  ]),
  alignItems: PropTypes.oneOf([
    "start", "end", "center",
  ]),
  justifyContent: PropTypes.oneOf([
    "left", "right", "center",
  ]),
  sizeItems: PropTypes.oneOf([
    "xs1-sm2-md3",
      "flex-1"
  ]),
  spaceItems: PropTypes.oneOf([
    "between-1",
  ]),
  background: PropTypes.oneOf([
    "white", "red", "gray", "gray-light"
  ]),
  appear: PropTypes.oneOf(
      Object.keys(AppearTypes)
  ),
  onAppear: (
      PropTypes.func
  ),
}
Container.propTypes = ContainerPropTypes

export default Container