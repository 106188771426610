import React from "react"
import Responsive from "../../../utils/responsive"

const ResponsiveHelper = () => (
  <div className={"responsive-helper"}>
    { Object.keys(Responsive.breakpoints).map(item => (
      <span
        key={`responsive-helper-${item}`}
        className={item}
      >{ item.toUpperCase() }</span>
    )) }
  </div>
)

export default ResponsiveHelper