import React from 'react'
import lottie from "lottie-web"

const Animation = (props) => {

  let lottieRef = React.createRef()
  let [anim, setAnim] = React.useState(undefined)

  // ignore missing dependencies in useEffect to load animation
  /* eslint-disable */

  React.useEffect(() => {
    if(anim === undefined) {
      const animation = lottie.loadAnimation({
        container: lottieRef.current,
        animationData: require(`../../../static/assets/animations/${props.src}`),
        autoplay: props.autoplay,
        loop: props.loop,
        renderer: "svg",
      })
      setAnim(animation)
    }
    return () => anim && anim.destroy()
  }, [])

  /* eslint-enable */

  React.useEffect(() => {
    if(anim) {

      anim.loop = props.loop
      anim.setDirection(props.direction)
      anim.setSpeed(props.speed)

      if(anim.isPaused !== props.paused) {
        if(props.paused) {
          anim.pause()
        }
        else {
          anim.play()
        }
      }

      anim.removeEventListener("complete")
      if( !(props.loop) ) {
        if(props.reset) {
          anim.addEventListener("complete", () => {
            anim.pause()
            anim.stop()
          })
        }
        anim.addEventListener("complete", () => {
          props.onComplete && props.onComplete()
        })
      }
    }
  }, [ anim, props ])

  return (
    <div
      className={"animation"}
      role={"button"}
      aria-label={"animation"}
      tabIndex={props.tabIndex}
      style={props?.style ?? {}}
    >
      <div className={"lottie"} ref={lottieRef} tabIndex={props.tabIndex} />
    </div>
  );
}

Animation.defaultProps = {
  loop: true,
  autoplay: false,
  paused: true,
  tabIndex: 0,
  reset: true,
  direction: 1,
  speed: 1,
}

export default Animation;