const AppearTypes = {
  fromTop: {
    onIntersection: false,
    fromTop: true,
    delay: 1000,
  },
  fromLeft: {
    onIntersection: true,
    fromLeft: true,
    delay: 1000,
  },
  instantFromLeft: {
    onIntersection: false,
    fromLeft: true,
    delay: 200,
  },
  fromRight: {
    onIntersection: true,
    fromRight: true,
    delay: 1000,
  },
  fromBottom: {
    onIntersection: true,
    fromBottom: true,
    delay: 1000,
  },
}

export default AppearTypes