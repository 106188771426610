import React from "react"
import Image from "../../image"

import styles from "./index.module.scss"


const SubFooter = () => {
  return (
    <div className={styles.subfooter}>

      <Image className={styles.logo}
        src={"/assets/icons/tisax.svg"}
        alt={"TISAX® results available"}
      />
    </div>
  )
}

export default SubFooter