import { useCallback, useEffect, useState } from "react"

const useDelayed = (startingValue, laterValue, delay) => {
  const [value, setValue] = useState(startingValue)

  const timeoutFunc = useCallback(() => {
    const timer = setTimeout(() => {
      setValue(laterValue)
    }, delay)
    return () => clearTimeout(timer)
  }, [laterValue, delay])

  useEffect(() => timeoutFunc(), [timeoutFunc])

  return [value, timeoutFunc]
}

export default useDelayed
