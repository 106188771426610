import React, { Component } from "react"

import clsx from "clsx"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Animation from "../../animation";
import Icon from "../../icon";

const NavLinks = [
  {
    path: "/ueber-uns",
    label: "Über uns",
  },
  {
    path: "/leistungen",
    label: "Leistungen",
  },
  {
    path: "/karriere",
    label: "Karriere",
  },
  {
    path: "/kontakt",
    label: "Kontakt",
  },
]

class Navigation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    }
  }

  render() {

    if(typeof document !== 'undefined') {

      if(this.state.open) {
        document.body.setAttribute("style",
          `overflow-y: hidden;`
        )
      }
      else {
        document.body.setAttribute("style", "")
      }
    }

    return (
      <nav className={clsx("navigation",
        this.state.open && "open",
      )}>
        <ul>
          <li className={clsx("home",
            this.props.path === "/" && "current"
          )}>
            <Link to={this.props.path === "/" ? undefined : "/"}>
              <Icon type={"red-oak"} />
              <span className={"label"}>Red Oak Consulting</span>
            </Link>
          </li>
          { NavLinks.map(link => (
            <li className={clsx("nav-link",
              this.props.path.startsWith(link.path) && "current"
            )}>
              <Link
                tabIndex={0}
                to={link.path === this.props.path ? undefined : link.path}
              >
                <span>{ link.label }</span>
              </Link>
            </li>
          )) }
          <li
            className={"burger"}
            onClick={() => this.setState({ open: !(this.state.open) })}
          >
            <Animation
              src={"burger.json"}
              loop={false}
              paused={false}
              reset={false}
              speed={2}
              direction={ this.state.open ? 1 : -1 }
            />
          </li>
        </ul>
      </nav>
    )
  }
}

Navigation.propTypes = {
  path: PropTypes.string,
}

export default Navigation
