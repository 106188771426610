import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"

import Appear from "../appear"
import AppearTypes from "../appear/types"
import Icon from "../icon"
import Responsive from "../../utils/responsive";

const Box = (props) => {

  // ignore that aria role is not interactive
  /* eslint-disable */

  const View = (appearClasses) => (
    <div className={clsx("box", props.className,
      props.background && `background-color-${props.background}`,
      props.backgroundNumber && `background-number`,
      props.border && `border-${props.border}`,
      props.borderColor && `border-color-${props.borderColor}`,
      props.boxShadow && `box-shadow-${props.boxShadow}`,
      props.display && `display-${props.display}`,
      props.size && `size-${props.size}`,
      props.padding && `padding-${props.padding}`,
      props.alignText && `text-align-${props.alignText}`,
      props.sizeText && `text-size-${props.sizeText}`,
      props.invisibleFrom && `invisible-from-${props.invisibleFrom}`,
      props.invisibleTo && `invisible-to-${props.invisibleTo}`,
      appearClasses,
    )}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
         onClick={props.onClick}
         style={props.style}


    >
      { props.border && <div className={"border"} /> }
      { props.backgroundNumber && <div className={"number"}>
        <Icon type={`number-${props.backgroundNumber}`} />
      </div> }
      <div className={"content"}>
        { props.children }
      </div>
    </div>
  )

  /* eslint-enable */

  return props.appear
    ? <Appear asUtil { ...AppearTypes[props.appear]}>{ View }</Appear>
    : View()
}

Box.propTypes = {
  className: (
    PropTypes.string
  ),
  background: PropTypes.oneOf([
    "white", "red", "gray", "gray-light"
  ]),
  backgroundNumber: PropTypes.oneOf(
    Array.from(
      new Array(8),
      (_, idx) => idx + 1
    )
  ),
  border: PropTypes.oneOf([
    "left", "left-overlay", "left-underlay", "left-hover",
    "right", "right-overlay", "right-underlay",
    "none-md-left",
  ]),
  borderColor: PropTypes.oneOf([
    "red", "gray",
  ]),
  boxShadow: PropTypes.oneOf([
    "bottom-right",
  ]),
  display: PropTypes.oneOf([
    "no-flex",
  ]),
  padding: PropTypes.oneOf([
    "full", "full-2",
    "horizontal", "horizontal-2", "horizontal-sm0",
    "vertical", "vertical-2",
    "left", "right", "top", "bottom",
  ]),
  size: PropTypes.oneOf([
    "1-to-3", "2-to-3", "xs-100", "content", "fill", "2-to-5",
  ]),
  alignText: PropTypes.oneOf([
    "left", "right", "center", "justify",
  ]),
  sizeText: PropTypes.oneOf([
    "small", "normal", "big", "bigger",
  ]),
  invisibleFrom: PropTypes.oneOf(
    Object.keys(Responsive.breakpoints)
  ),
  invisibleTo: PropTypes.oneOf(
      Object.keys(Responsive.breakpoints)
  ),
  appear: PropTypes.oneOf(
    Object.keys(AppearTypes)
  ),
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
}

export default Box
