import React from "react"

import clsx from "clsx"
import PropTypes from "prop-types"

import Appear from "../appear";
import AppearTypes from "../appear/types";

const Section = (props) => {

  const View = (appearClasses) => (
    <section className={clsx("section",
      props.className,
      appearClasses
    )} style={props.style} >
      { props.children }
    </section>
  )

  return props.appear
    ? <Appear asUtil { ...AppearTypes[props.appear] } onAppear={props.onAppear}>
      { View }
    </Appear>
    : View()
}

Section.propTypes = {
  className: (
    PropTypes.string
  ),
  appear: PropTypes.oneOf(
    Object.keys(AppearTypes)
  ),
  onAppear: (
    PropTypes.func
  ),
}

export default Section