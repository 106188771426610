import {useMediaQuery} from "react-responsive/src";

const Responsive = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  getCurrentBreakpoint: () =>
    Object.values(Responsive.breakpoints).filter((val, idx, arr) =>
      useMediaQuery({ minWidth: val })
    ).pop(),
}

export default Responsive