import "intersection-observer"
import React from "react"

import PropTypes from "prop-types"

import ResponsiveHelper from "./responsive"
import Header from "./header"
import Footer from "./footer"
import SubFooter from "./subfooter"

import "../../styles/reset.scss"
import "../../styles/index.scss"

const Layout = (props) => {

  return (
    <div className={"layout"}>
      { process.env.NODE_ENV === "development" && <ResponsiveHelper /> }
      <Header path={props.path} />
      <main className={"main"}>{ props.children }</main>
      <Footer />
      <SubFooter />
    </div>
  )
}

Layout.propTypes = {
  path: PropTypes.string,
}

export default Layout
