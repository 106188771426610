import React, { useCallback, useState } from "react"

import clsx from "clsx"
import PropTypes from "prop-types"
import Observer from "@researchgate/react-intersection-observer"

import useDelayed from "../../utils/useDelayed"
const Appear = (props) => {

  const [show] = useDelayed(false, true, props.delay)
  const [intersected, setIntersected] = useState(false)

  const intersectionCallback = useCallback(
    obj => {
      if(obj.intersectionRatio > props.intersectionThreshold) {
        setIntersected(true)
        props.onAppear && props.onAppear()
      }
    },[setIntersected, props]
  )

  const appearClasses = {
    "appear": true,
    "from-left": props.fromLeft,
    "from-right": props.fromRight,
    "from-top": props.fromTop,
    "from-bottom": props.fromBottom,
    "show": props.onIntersection ? show && intersected : show,
  }

  const View = props.asUtil
    ? props.children(appearClasses)
    : <div className={ clsx(appearClasses) }>{ props.children }</div>

  const options = {
    onChange: intersectionCallback,
    threshold: props.intersectionThreshold,
  }

  return props.onIntersection
    ? <Observer {...options}>{View}</Observer>
    : View
}

Appear.propTypes = {
  delay: PropTypes.number.isRequired,
  onIntersection: PropTypes.bool,
  onAppear: PropTypes.func,
  intersectionThreshold: PropTypes.number,
  fromLeft: PropTypes.bool,
  fromRight: PropTypes.bool,
  fromTop: PropTypes.bool,
  fromBottom: PropTypes.bool,
  asUtil: PropTypes.bool,
}

Appear.defaultProps = {
  delay: 100,
  intersectionThreshold: 0.2,
}

export default Appear
