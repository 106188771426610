import React from "react"
import PropTypes from "prop-types"

import Navigation from "../navigation"
import Appear from "../../appear";
import AppearTypes from "../../appear/types";

const Header = (props) => {
  return (
    <header className={"header"}>
      <div className={"main"}>
        <Appear { ...AppearTypes.fromTop}>
          <Navigation
            path={props.path}
          />
        </Appear>
      </div>
    </header>
  )
}

Header.propTypes = {
  path: PropTypes.string,
}

export default Header
